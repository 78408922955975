<template>
  <div>
    <KTCodePreview v-bind:title="'Kích hoạt danh sách IMEI'">
      <template v-slot:preview>
        <b-form ref="form" class="row">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <div class="form-group">
                  <span>
                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                    đúng format, hoặc có thể tải ở
                    <a :href="urlExcel">đây</a>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span> <b>Bước 2:</b> Tiến hành Import </span>
                <div style="padding-top: 15px">
                  <b-form-file
                    placeholder="Hãy chọn một tập tin"
                    @change="selectFile"
                    v-model="file"
                    ref="file-input"
                    class="mb-2"
                  ></b-form-file>
                </div>
                <b-progress :value="progress" :max="100" show-progress>
                  <b-progress-bar
                    :value="progress"
                    :label="`${progress.toFixed(2)}%`"
                  ></b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
          </b-container>
          <!-- End input session -->

          <b-container class="mt-10">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
                @change="onChangeStatus()"
              >
                <b-form-radio value="all"
                  >Tất cả
                  <span class="text-primary"
                    >({{ countAll }})</span
                  ></b-form-radio
                >
                <b-form-radio value="ready"
                  >Chưa gửi
                  <span class="text-danger"
                    >({{ countReady }})</span
                  ></b-form-radio
                >
                <b-form-radio value="on-active"
                  >Đang kích hoạt
                  <span class="text-dark"
                    >({{ countOnActive }})</span
                  ></b-form-radio
                >
                <b-form-radio value="actived"
                  >Đã kích hoạt
                  <span class="text-success"
                    >({{ countActived }})</span
                  ></b-form-radio
                >
                <b-form-radio value="error"
                  >Lỗi
                  <span class="text-warning"
                    >({{ countError }})</span
                  ></b-form-radio
                ></b-form-radio-group
              >
            </b-form-group>
            <b-table
              class="myTable"
              bordered
              hover
              :fields="fields"
              :items="importItems"
              :per-page="50"
              :current-page="currentPage"
              id="my-table-product"
            >
              <template v-slot:cell(status)="row">
                <b-row class="p-2">
                  <span
                    v-text="checkStatus(row.item.status)"
                    class="label font-weight-bold label-lg label-inline"
                    v-bind:class="{
                      'label-light-warning': row.item.status === 1,
                      'label-light-success': row.item.status === 2,
                      'label-light-danger': row.item.status === 3,
                      'label-light-default': row.item.status === 4,
                    }"
                    style="width: max-content"
                  ></span>
                  <b-icon
                    v-if="row.item.status === 4"
                    icon="arrow-clockwise"
                    variant="warning"
                    class="mt-2 ml-2"
                    v-b-tooltip
                    title="Thử lại"
                    @click="reActiveImei(row.item)"
                  ></b-icon>
                </b-row>
              </template>
            </b-table>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="importItems.length"
              :per-page="50"
              aria-controls="my-table-product"
              align="right"
            ></b-pagination>
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="fontweight: 600; width: 100px"
              variant="primary"
              size="sm"
              @click="importProduct"
              >Kích hoạt</b-button
            >
            <router-link to="/products" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  width: max-content !important;
  color: #464e5f;
  background-color: transparent;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import xlsx from 'xlsx';
import ApiService from '@/core/services/api.service';
import { SAMSUNG_STATUS, URL_IMPORT_ACTIVE_IMEI } from '../../../utils/constants';
import moment from 'moment';
import { cloneDeep } from '../../../utils/common';

export default {
  data() {
    return {
      fields: [
        {
          key: 'sellDate',
          label: 'Ngày',
          thStyle: { width: '10%' },
        },
        {
          key: 'imeiCode',
          label: 'Mã IMEI',
          thStyle: { width: '15%' },
        },
        {
          key: 'customerPhone',
          label: 'SĐT khách hàng',
          thStyle: { width: '15%' },
        },
        {
          key: 'showroomCode',
          label: 'Mã showroom',
          thStyle: { width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { width: '10%' },
        },
        {
          key: 'message',
          label: 'Thông báo',
          thStyle: { width: '40%' },
        },
      ],
      importItems: [],
      tmpItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      urlExcel: URL_IMPORT_ACTIVE_IMEI,
      currentPage: 1,
      selectedStatus: 'all',
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách IMEI', route: '/imeis' },
      { title: 'Danh sách IMEI', route: '/imeis' },
      { title: 'Kích hoạt danh sách IMEI' },
    ]);
  },
  computed: {
    countAll() {
      return this.tmpItems.length;
    },
    countReady() {
      return this.tmpItems.filter(
        (item) => item.status === SAMSUNG_STATUS.READY
      ).length;
    },
    countOnActive() {
      return this.tmpItems.filter(
        (item) => item.status === SAMSUNG_STATUS.ON_ACTIVE
      ).length;
    },
    countActived() {
      return this.tmpItems.filter(
        (item) => item.status === SAMSUNG_STATUS.ACTIVED
      ).length;
    },
    countError() {
      return this.tmpItems.filter(
        (item) => item.status === SAMSUNG_STATUS.ERROR
      ).length;
    },
  },
  methods: {
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    importProduct: async function () {
      this.uploading = true;
      if (!this.importItems) {
        return;
      }

      this.importItems.map((item) => {
        item.status = 2;
      });

      this.onCallApiActive(this.importItems);
    },
    onCallApiActive(listData) {
      ApiService.post('imeis/active-imei', listData)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            response.data.data.map((response) => {
              const importItem = this.importItems.find(
                (item) => item.indexNo === response.indexNo
              );
              if (importItem) {
                importItem.status =
                  response.status === 1
                    ? SAMSUNG_STATUS.ACTIVED
                    : SAMSUNG_STATUS.ERROR;
                importItem.message = response.message;
              }
            });
            this.clearFiles();
            this.tmpItems = cloneDeep(this.importItems);
            this.onChangeStatus();
          } else {
            this.makeToastFaile(message);
          }
          this.uploading = false;
        })
        .catch((e) => {
          console.log('Catch: ', e);
          this.makeToastFaile(e);
          this.uploading = false;
        });
    },
    selectFile(event) {
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          });
          const excellist = [];
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.excellist = excellist;
          if (this.excellist.length > 500) {
            return alert(
              'Để đám bảo hệ thống hoạt động ổn định, chỉ hỗ trợ kích hoạt tối đa 500 IMEI trên một lần thao tác!'
            );
          }
          this.importItems = this.excellist.map((element, index) => {
            return {
              sellDate: element['Ngày'],
              imeiCode: element['IMEI'],
              customerPhone: element['SĐT Khách hàng'],
              showroomCode: element['Showroom'],
              indexNo: `AT${index}` + moment(),
              status: SAMSUNG_STATUS.READY,
              message: '',
            };
          });
          this.tmpItems = cloneDeep(this.importItems);
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkStatus: function (item) {
      switch (item) {
        case SAMSUNG_STATUS.READY:
          return 'Chưa gửi';
        case SAMSUNG_STATUS.ON_ACTIVE:
          return 'Đang kích hoạt';
        case SAMSUNG_STATUS.ACTIVED:
          return 'Đã kích hoạt';
        case SAMSUNG_STATUS.ERROR:
          return 'Lỗi';
        default:
          return '';
      }
    },
    reActiveImei(item) {
      item.status = SAMSUNG_STATUS.ON_ACTIVE;
      this.onCallApiActive([item]);
    },
    onChangeStatus() {
      switch (this.selectedStatus) {
        case 'all':
          this.importItems = cloneDeep(this.tmpItems);
          break;
        case 'ready':
          this.importItems = this.tmpItems.filter(
            (tmpItem) => tmpItem.status === SAMSUNG_STATUS.READY
          );
          break;
        case 'on-active':
          this.importItems = this.tmpItems.filter(
            (tmpItem) => tmpItem.status === SAMSUNG_STATUS.ON_ACTIVE
          );
          break;
        case 'actived':
          this.importItems = this.tmpItems.filter(
            (tmpItem) => tmpItem.status === SAMSUNG_STATUS.ACTIVED
          );
          break;
        case 'error':
          this.importItems = this.tmpItems.filter(
            (tmpItem) => tmpItem.status === SAMSUNG_STATUS.ERROR
          );
          break;
      }
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
